import * as React from 'react'

import classNames from 'classnames'

import { MapObjectSubTypes } from '../../enums/enums'
import { PrismicPark } from '../../generated/graphql'
import { buildMapDataFromPrismicPark, MapObjectType } from '../../utils/mapHelper'
import MapViewer from '../MapViewer'

import './MapWrapper.scss'

interface MapDataBlockProps {
  mapObjects: Array<MapObjectType>
  title: string
}

interface MapWrapperProps {
  park: PrismicPark
}

const Checkbox = ({ className, data }) => {
  const [checked, setChecked] = React.useState(data.isShowing)

  const handleChange = () => {
    setChecked(!checked)
    data.isShowing = checked
    data.onToggle(data)
  }

  return <input className={className} type="checkbox" checked={checked} onChange={handleChange} />
}

const MapDataBlock: React.FC<MapDataBlockProps> = ({ mapObjects, title }) => {
  const [isOpen, setOpen] = React.useState(false)

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  return (
    <li className={classNames('accordion-item', { 'is-active': isOpen })}>
      <div className="accordion-title" onClick={() => toggleOpen()}>
        {title}
      </div>
      <div className="accordion-content">
        <ul>
          {mapObjects.map((mapObject) => (
            <li
              className="grid-x map-data-row"
              onClick={(event: any) => event.stopPropagation() || mapObject.onClick(mapObject, null)}
              key={mapObject.index}
            >
              <div
                className="icon"
                style={{
                  backgroundImage: `url("${mapObject.icon}")`,
                }}
              />
              <div className="title">{mapObject.title}</div>
              <Checkbox className="checkbox" data={mapObject} />
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

const MapWrapper: React.FC<MapWrapperProps> = ({ park }) => {
  const mapData = buildMapDataFromPrismicPark(park)

  const kmlGroups = {}
  mapData.kmlFiles.forEach((kmlFile) => {
    if (!(kmlFile.mapObjectSubType in kmlGroups)) {
      let title
      switch (kmlFile.mapObjectSubType) {
        case MapObjectSubTypes.BOUNDARY_INDIGENOUS_TERRITORY:
          title = 'Indigenous Territory Boundaries'
          break
        case MapObjectSubTypes.BOUNDARY_NATIONAL_PARK:
          title = 'National Park Boundaries'
          break
        default:
      }

      kmlGroups[kmlFile.mapObjectSubType] = {
        subType: kmlFile.mapObjectSubType,
        title,
        records: [],
      }
    }
    kmlGroups[kmlFile.mapObjectSubType].records.push(kmlFile)
  })

  return (
    <div className="grid-x map-viewer park-section">
      <div className="map-wrapper">
        <MapViewer mapData={mapData} />
      </div>
      <div className="map-data">
        <ul className="accordion">
          {Object.values(kmlGroups).map((kmlGroup) => (
            <MapDataBlock key={kmlGroup.subType} mapObjects={kmlGroup.records} title={kmlGroup.title} />
          ))}
          <MapDataBlock
            key={MapObjectSubTypes.MARKER_VISITOR_CENTER}
            mapObjects={mapData.markers}
            title="Visitor Centors"
          />
        </ul>
      </div>
    </div>
  )
}

export default MapWrapper
