import * as React from 'react'

import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import ImageCarousel from '../../components/ImageCarousel'
import Layout from '../../components/Layout'
import MapWrapper from '../../components/MapWrapper'
import Seo from '../../components/Seo'
import { Query } from '../../generated/graphql'

import './Park.scss'

type ParkTemplateProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const Park: React.FC<ParkTemplateProps> = ({ data }) => {
  if (!data) return null
  const park = data.prismicPark.data
  const title = RichText.asText(park.title.richText)
  const images = data.prismicPark.data.park_data.images.map((image) => ({
    title: image.title,
    url: image.url,
  }))

  return (
    <Layout>
      <Seo title={title} />
      <div className="park">
        <div className="wrapper">
          <div className="upper">
            <h1>{title}</h1>
          </div>
          <ImageCarousel images={images} />
          <MapWrapper park={data.prismicPark} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ParkQuery($prismicId: ID) {
    prismicPark(prismicId: { eq: $prismicId }) {
      _previewable
      data {
        title {
          richText
        }
        park_data {
          dates {
            date
            type
          }
          id
          images {
            alt_text
            caption
            credit
            title
            url
          }
          kml_files {
            name
            type
            url
          }
          links {
            title
            type
            url
          }
          location {
            lat
            lng
          }
          name
          prismicId
          unit_code
          visitor_centers {
            has_passport_stamp
            location {
              lat
              lng
            }
            name
          }
        }
      }
      prismicId
      url
      uid
    }
  }
`
export default withPrismicPreview(Park)
